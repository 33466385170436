body {
  position: relative;
	font-family: "Georgia", "Times New Roman", Serif;
	font-size: 16px;
	line-height: 25px;
  color: #323232;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 1440px;
  background: linear-gradient(0deg, #f5f5f5, #eee);
  background-color: white;
  box-shadow: 0 0 40px rgba(0,0,0,0.1);
}

h1 {
  font-family: 'itc-avant-garde-gothic-pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  margin-top: 0;
}

h2 {
  font-family: 'itc-avant-garde-gothic-pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 31px;
  margin: 0;
}

h3 {
  font-family: 'itc-avant-garde-gothic-pro', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin: 0;
}

h4 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}

h5 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
  margin: 0;
  line-height: 16px;
}

/*For FullScreenModal and overlay*/
.stopscroll {
  height: 100%;
  overflow: hidden;
}


@media screen and (min-width: 768px) {
  html {
    text-rendering: optimizeLegibility;
	}
}
@media screen and (max-width: 767px) {
  html {
    overflow: scroll;
    overflow-x: hidden;
  }
  html::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

a {
	color: #FF454C;
	text-decoration: underline;
}
a:hover, a:active {
  text-decoration: none;
}
em {
	font-style: italic;
}
b {
  font-style: bold;
}
::selection {
	background: #dddddd;
}
/*override flexbox-grid to get full bleed*/
.row {
  margin-left: 0;
  margin-right: 0;
}

.activeNavLink {
  background-color: #eee;
}

.twitter-share-button {
  margin-left: 3px;
}
